// import { close } from "fs";


var app = {};

app.init = function() {
    app.carousel();
    app.hamburger();
    app.navImages();
    app.galleryModal(); 
    app.lyrics();
    app.videoModal();
    app.parallax();
    app.audio();
    app.newsPost();
    app.svgTitle();
};


app.videoModal = function() {

    const videoModal = document.querySelector('.video-modal');
    const closeButton = document.querySelector(".close-video-modal");

    let previousActiveElement;

    if (videoModal) {
        $('.video-button').on('click', function() {

            previousActiveElement = document.activeElement;
            let youTubeId = this.dataset.video;
            let iframeTitle = this.dataset.title;
            let youTubeLink = 'https://www.youtube-nocookie.com/embed/' + youTubeId;
            $("#modalIframe").attr('src', youTubeLink);
            $("#modalIframe").attr('title', iframeTitle);
            videoModal.classList.add('showing')
            $(videoModal).attr('aria-hidden', 'false');
            closeButton.focus();
            return previousActiveElement;
        });

        $('.video-button').focus(function() {
            if ($('.video-modal').hasClass('showing')) {
                closeVideoModal();
            }
        })


        closeButton.addEventListener('click', closeVideoModal);
        videoModal.addEventListener('click', closeVideoModal);

        $(document).keydown(function(event) {
            if (event.keyCode == 27) {
                closeVideoModal();
            }

        });

        function closeVideoModal() {
            $("#modalIframe").attr('src', '');
            $("#modalIframe").attr('title', '');
            videoModal.classList.remove('showing')
            $(videoModal).attr('aria-hidden', 'true');
            // previousActiveElement.focus();
        };
    }
};

//Mobile Hamburger Menu
app.hamburger = function() {

    $('.menu-button-open').on("click", function() {
        $("body").addClass('mobile-menu-open');
        $(".navigation").addClass("showing").attr("aria-expanded", "true");
    });

    $('.menu-button-close').on("click", function() {
        $("body").removeClass('mobile-menu-open');
        $(".navigation").removeClass("showing").attr("aria-expanded", "false");
        $("#menubutton").focus();
    });
    // Pressing on the escape key closes the mobile menu  
    $(document).keyup(function(e) {
        let home = $('.home');
        if (e.key === "Escape") {
            if ($("body").hasClass('mobile-menu-open')) {
                $('.navigation').removeClass('showing').attr("aria-expanded", "false");
                $("body").removeClass('mobile-menu-open');
                $('#menubutton').focus();
                $('.main-nav-image').css('z-index', '16').attr('aria-hidden', 'true');
                $('.main-nav-image-1').css('z-index', '17').attr('aria-hidden', 'false');
                if (home) {
                    homeCarousel.flickity('unpausePlayer');
                }
            }
        }
    });

    // Tabbing through last language menu closes the modal
    $(".header-social li:last-child a").focusout(function() {
        $(".navigation").removeClass("showing").attr("aria-expanded", "false");
        $("body").removeClass("mobile-menu-open");
        $('.main-nav-image').css('z-index', '16').attr('aria-hidden', 'true');
        $('.main-nav-image-1').css('z-index', '17').attr('aria-hidden', 'false');
    })
}

app.navImages = function() {

    let spansWithData = $('.nav-image');
    let imgContainer = $('.navigation-images')
    let mainNavItem = 1;
    let navImageHtml = "";

    spansWithData.each((index, span) => {
        let imageSrc = $(span).attr("data-image");
        let imageAlt = $(span).attr("data-alt");
        navImageHtml += `<div aria-hidden="true" style="background-image: url(${imageSrc});" class="main-nav-image main-nav-image-${mainNavItem}"><span class="screen-reader-text image-alt-${mainNavItem}">${imageAlt}</span></div>`;
        mainNavItem++;
    })

    imgContainer.html(navImageHtml);

    $('.menu-button-open').on('click', function() {
        $('.main-nav-image').css({
            'z-index': '16'
        });
        $('.main-nav-image-1.main-nav-image').css('z-index', '17').attr('aria-hidden', 'false');
    });


    $('.navigation-items a').on('focus mouseenter', function() {
        let hasImage = $(this).find('.nav-image');
        if (hasImage.length > 0) {
            let currentFocus = $(this).find('.nav-image').attr('id');
            let currentImg = '.' + currentFocus;

            $('.main-nav-image').css('z-index', '16').attr('aria-hidden', 'true');
            $(currentImg).css('z-index', '17').attr('aria-hidden', 'false');
        }
    });

    $('.menu-button-close').on('click', function() {
        $('.main-nav-image').css('z-index', '16').attr('aria-hidden', 'true');
        $('.main-nav-image-1').css('z-index', '17').attr('aria-hidden', 'false');
    });

}

app.lyrics = function() {
    $('.lyrics-header').on('click', function() {
        var currentSong = $(this).parent().parent('.each-track');
        $(currentSong).siblings().removeClass('lyrics-showing');
        $(currentSong).toggleClass('lyrics-showing');
    })

    // On interaction with the lyric button change aria label
    $('button.lyrics-header').on('click', function() {
        var expanded_setting = $(this).attr('aria-expanded');
        if (expanded_setting == 'false') {
            $(this).attr('aria-expanded', 'true');
        } else {
            $(this).attr('aria-expanded', 'false');
        }
    });

}

app.galleryModal = function() {

    var currentIndex = "";

    $('.previewImage').on('click', function() {
        currentIndex = $(this).data('index');
        callFlick(currentIndex);
        launchModal();
    });

    function callFlick(i) {
        $('.main-carousel').flickity({
            cellAlign: 'left',
            contain: true,
            wrapAround: true,
            adaptiveHeight: true,
            imagesLoaded: true,
            initialIndex: i
        });
    };

    function launchModal() {
        $('.gallery-modal').addClass('modal-showing');
    };

    $('.gallery-modal-close').on('click', function() {
        $('.gallery-modal').removeClass('modal-showing');
        $('.main-carousel').flickity('destroy');
    });
}

app.parallax = function() {

    let windowSize = $(window).width();

    $(window).resize(function() {
        windowSize = $(window).width();
        return windowSize;
    });

    if (windowSize > 750) {
        let isParallax = $('.parallax-wrapper');
        let isBlur = $('.blur-wrapper');
        let isTextParallax = $('.text-parallax-wrapper');
        let isTextFade = $('.text-fade-wrapper');

        if (isParallax) {
            var controller = new ScrollMagic.Controller();

            $('.parallax-wrapper').each((index, wrapper) => {

                let imageMove = $(wrapper).find('.parallax-image');

                let tween = new TimelineMax()
                    .add([
                        TweenMax.to(imageMove, 1, { top: '0px', ease: Linear.easeNone }),
                    ]);

                let scene = new ScrollMagic.Scene({ triggerElement: $(wrapper)[0], duration: "100%", triggerHook: 1 })
                    .setTween(tween)
                    //.addIndicators()
                    .addTo(controller);
            })
        }

        if (isTextParallax) {
            var controller = new ScrollMagic.Controller();

            $('.text-parallax-wrapper').each((index, wrapper) => {

                let textMove = $(wrapper).find('.parallax-text');
                let tween = new TimelineMax()
                    .add([
                        TweenMax.to(textMove, 1, { top: '0px', ease: Linear.easeNone }),
                    ]);

                let scene = new ScrollMagic.Scene({ triggerElement: $(wrapper)[0], duration: "100%", triggerHook: 1 })
                    .setTween(tween)
                    //.addIndicators()
                    .addTo(controller);
            })
        }

        if (isBlur) {
            var controller = new ScrollMagic.Controller();
            $('.blur-wrapper').each((index, wrapper) => {
                let imageBlur = $(wrapper).find('.blur-image');
                let tween = new TimelineMax()
                    .add([
                        TweenMax.fromTo(imageBlur, 1, { filter: 'blur(20px)', immediateRender: false }, { filter: 'blur(0)', ease: Linear.easeNone })
                    ]);

                let scene = new ScrollMagic.Scene({ triggerElement: $(wrapper)[0], duration: "20%", triggerHook: 1 })
                    .setTween(tween)
                    //.addIndicators()
                    .addTo(controller);
            })
        }

        if (isTextFade) {
            var controller = new ScrollMagic.Controller();
            $('.text-fade-wrapper').each((index, wrapper) => {
                let textFade = $(wrapper).find('.fade-text');
                let tween = new TimelineMax()
                    .add([
                        TweenMax.to(textFade, 1, { opacity: 1, ease: Linear.easeNone })
                    ]);

                let scene = new ScrollMagic.Scene({ triggerElement: $(wrapper)[0], duration: "20%", triggerHook: 1 })
                    .setTween(tween)
                    //.addIndicators()
                    .addTo(controller);
            })
        }
    }
}

app.audio = function() {

    let allAudios = document.querySelectorAll('.audio-content');
    if (allAudios) {
        allAudios.forEach(function(audio) {

            let audioElement = audio.querySelector('.audio-clip');
            let audioControl = audio.querySelector('.audio-control');
            let audioWrapper = audio;

            audioControl.onclick = function() {
                let isThisPlaying = audioWrapper.classList.contains('playing');

                if (isThisPlaying == true) {
                    audioControl.innerHTML = '<i class="fa fa-play"></i><span class="screen-reader-text">play song</span>';
                    audioWrapper.classList.remove('playing');
                    audioElement.pause();
                    return false;
                }

                let alreadyPlaying = document.querySelectorAll('.playing')[0];

                if (alreadyPlaying != undefined) {
                    alreadyPlaying.classList.remove('playing');

                    let otherElement = alreadyPlaying.querySelector('.audio-clip');
                    let otherControl = alreadyPlaying.querySelector('.audio-control');

                    otherControl.innerHTML = '<i class="fa fa-play"></i><span class="screen-reader-text">play song</span>';
                    otherElement.pause();
                }

                let pause = audioControl.innerHTML === '<i class="fa fa-pause"></i><span class="screen-reader-text">pause song</span>';
                audioControl.innerHTML = pause ? '<i class="fa fa-play"></i><span class="screen-reader-text">play song</span>' : '<i class="fa fa-pause"></i><span class="screen-reader-text">pause song</span>';

                let method = pause ? 'pause' : 'play';
                audioElement[method]();

                audioWrapper.classList.add('playing');

                return false;
            };
        })
    }
}

app.carousel = function() {
    // carousel options
    var drag = false

    if (matchMedia('(max-width: 767px)').matches) {
        drag = true;
    }

    var carousel_options = {
        cellSelector: '.header-slide',
        pageDots: false,
        cellAlign: 'center',
        imagesLoaded: true,
        contain: false,
        draggable: drag,
        fade: true,
        autoPlay: 7000,
        wrapAround: true
    }

    var homeCarousel = $('.header-carousel');

    // load flickity
    homeCarousel.flickity(carousel_options);

    // On the change of the active carousel image change the corresponding carousel item
    homeCarousel.on('change.flickity', function(event, index) {
        $('.header-slide-active-slide').text(index + 1);
    });

    // Move the carousel buttons for accessibilty
    $(".header-carousel .flickity-prev-next-button").insertBefore(".flickity-slider");

    $(".play").on("click", function() {
        //console.log("clicked")
        homeCarousel.flickity('playPlayer');
        $(this).removeClass('active');
        $(".pause").addClass('active');
    });

    $(".header-carousel .pause").click(function() {
        homeCarousel.flickity('pausePlayer');
        $(this).removeClass('active');
        $(".play").addClass('active');
    });

    // Pause flickity when entering the main navigation
    $('.menu-button-open').on("click", function() {
        // console.log('keyboard fire');
        var homeCarousel = $('.header-carousel');
        homeCarousel.flickity('pausePlayer');
    });

    $('.menu-button-close').on("click", function() {
        homeCarousel.flickity('unpausePlayer');
        // console.log('keyboard fire close');
    });

    //set a title attribute for all iframes in the slider
    var videoSlides = document.querySelectorAll('.header-slide-video');
    if (videoSlides) {
        videoSlides.forEach(function(videoSlide) {
            let videoTitle = videoSlide.dataset.title;
            let videoIframe = videoSlide.querySelector('iframe');
            videoIframe.setAttribute('title', videoTitle);
        })
    }

}

app.newsPost = function() {
    // Force Add to Any buttons to have better titles
    var language = $('html').attr('lang');

    if (language == 'en-CA') {
        $('.a2a_button_pinterest').attr('title', 'Share on Pinterest');

        $('.a2a_button_facebook').attr('title', 'Share on Facebook');

        $('.a2a_button_twitter').attr('title', 'Share on Twitter');
    } else if (language == 'fr-CA') {
        $('.a2a_button_pinterest').attr('title', 'Partager sur Pinterest');

        $('.a2a_button_facebook').attr('title', 'Partager sur Facebook');

        $('.a2a_button_twitter').attr('title', 'Partager sur Twitter');
    }
}

// JavaScript function to add <title> element to SVG

app.svgTitle = function() {
    var prevButtonSVG = document.querySelector('.flickity-prev-next-button.previous > svg');
    var nextButtonSVG = document.querySelector('.flickity-prev-next-button.next > svg');

    if (prevButtonSVG) {
        prevButtonSVG.setAttribute('title', 'Previous');
    }

    if (nextButtonSVG) {
        nextButtonSVG.setAttribute('title', 'Next');
    }
}



$(document).ready(function() {
    //call all the app functions

    app.init();
})